import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  MenuIcon,
  XIcon,
  MapIcon,
  TemplateIcon,
} from '@heroicons/react/outline';
import logo from '../assets/g5954.png';

const navigation = [
  { name: 'About', current: false, routeURL: '/' },
  //{ name: 'Listings', current: false , routeURL: '/listings' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const PublicNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Disclosure as='nav' className='bg-gray-800'>
      {({ open }) => (
        <>
          <div className='mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex'>
                <div className='-ml-2 mr-2 flex items-center md:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
                <div
                  className='flex-shrink-0 flex items-center cursor-pointer'
                  onClick={() => navigate('/explore')}
                >
                  <img
                    className='block h-16 w-auto'
                    src={logo}
                    alt='Workflow'
                  />
                  {/* <img
                  className="hidden lg:block h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                  alt="Workflow"
                /> */}
                  <div>
                    <p className='text-1xl md:text-2xl md:block hidden text-white font-black ml-2'>
                      Doghouse
                    </p>
                  </div>
                </div>
                <div className='hidden md:ml-6 md:flex md:items-center '>
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={() => navigate(item.routeURL)}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white cursor-pointer',
                        'rounded-md text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
                  <button
                    type='button'
                    className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                    onClick={() => navigate('/sign-in')}
                  >
                    <span>Sign In</span>
                  </button>
                </div>
                <div className='ml-4 md:ml-4 md:flex-shrink-0 md:flex md:items-center'>
                  {location.pathname === '/map' ? (
                    <div>
                      <button
                        type='button'
                        className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                        onClick={() => navigate('/explore')}
                      >
                        <TemplateIcon
                          className='-ml-1 mr-2 h-5 w-5'
                          aria-hidden='true'
                        />
                        <span>List</span>
                      </button>
                    </div>
                  ) : (
                    <button
                      type='button'
                      className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                      onClick={() => navigate('/map')}
                    >
                      <MapIcon
                        className='-ml-1 mr-2 h-5 w-5'
                        aria-hidden='true'
                      />
                      <span>Map</span>
                    </button>
                  )}
                  {/* Profile dropdown */}
                  <Menu as='div' className='ml-3 relative'>
                    {({ open }) => (
                      <>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter='transition ease-out duration-200'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items
                            static
                            className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                          ></Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='md:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  onClick={() => navigate(item.routeURL)}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium cursor-pointer'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default PublicNavbar;
